export const ar = {
  MainPage: {
    Click_here: "انقر هنا",
    Blog: "المدونة",
    Products: "المنتجات",
    About: "من نحن",
    Team: "الفريق",
  },
  AboutPage: {
    FristParagraph:
      "في عالم يتسم بالتنوع والتطور، تبرز شركة الغذاء الطازج دهوك كرائدة في مجال التجارة العامة في العراق.",
    SecondParagraph:
      "نحن لسنا مجرد شركة، بل نحن رواد في توفير أفضل المنتجات والخدمات. باعتبارنا موزع رئيسي للعلامة التجارية فريش، نتبنى الجودة والتميز في كل خطوة نقوم بها.",
    ThirdParagraph:
      "نفخر بكوننا الوكيل الوحيد والحصري لشركة MHP الأوكرانية للعلامة التجارية كواليكو في العراق، وهذا يعكس تفانينا في تقديم أفضل المنتجات إلى عتبات منازل عملائنا.",
    FourthParagraph:
      "نحن نؤمن بالنزاهة والنظام في كل جانب من جوانب عملنا، ونلتزم بأعلى المعايير العالمية للصحة والسلامة. نحن هنا لنضمن أن كل منتج يصل إليك بحالة مثالية.",
    FifthParagraph:
      "انضم إلينا في رحلة الجودة والخدمة المتميزة. طعام طازج للتجارة العامة - حيث الطعم الفريد يلتقي بالجودة العالية.",
  },
  Intro: {
    Title: "الغذاء الطازج دهوك",
    SubTitle: "للتجارة العامة والإستيراد والتصدير",
  },
  Products: {
    qualikoButton: "منتجات كواليكو",
    freshButton: "منتجات فريش",
    FristTitle: "مالك العلامة التجارية فريش ",
    FristDescription_A:
      "إحدى الشركات التجارية الأولى والرائدة في المنطقة في تقديم جودة متميزة من الخدمات الزراعية والغذائية واللحوم والدجاج واللحوم المصنعة وأجزاء مختلفة من قطع الحيوانات.",
    FristDescription_B:
      "وخصصت الشركة مراعي لتربية الأغنام والمواشي الأخرى. تقوم شركتنا أيضًا بتربية طيور السمان بأعلى مستويات الجودة مع الحفاظ على الصدق والنزاهة.",
    SecondTitle:
      "الوكيل الحصري و الوحيد لشركة MHP الاوكرانية في العراق للعلامة التجارية كواليكو",
    SecondDescription:
      "الوكيل الحصري لشركة MHP التي تنتج لحوم الدجاج تحت العلامة التجارية Qulaiko حيث أننا أصحاب العلامة التجارية Fresh Food Duhok للحوم والدجاج التي يتم إنتاجها في مزارعنا الخاصة.",
  },
  TeamData: {
    Khaled: {
      Name: "خالد عمر أحمد الناصر",
      Desc: "مدير عام",
      Tag: "الرئيس التنفيذي والمؤسس",
    },
    Mahmoud: {
      Name: "محمود ابراهيم عطيه",
      Desc: "مدير العلاقات",
      Tag: "مدير العلاقات",
    },
    salesIQ: {
      Name: "مدير مبيعات العراق",
      Desc: "مدير المبيعات",
    },
    salesKRG: {
      Name: "مدير مبيعات كردستان",
      Desc: "مدير المبيعات",
    },
    accountant: {
      Name: "مدير الحسابات",
      Desc: "محاسب",
    },
  },
  QualikoPage: {
    title: "منتجات كواليكو",
    tabs: {
      fristTab: "دجاج مجمد",
      secondTab: "وجبات سريعة التحضير",
    },
  },
};