import image1 from "../assets/Images/frozenChicken/1.jpg";
import image2 from "../assets/Images/frozenChicken/2.png";
import image3 from "../assets/Images/frozenChicken/3.jpg";
import image4 from "../assets/Images/frozenChicken/4.jpg";
import image5 from "../assets/Images/frozenChicken/5.jpg";
import image6 from "../assets/Images/frozenChicken/6.jpg";
import image7 from "../assets/Images/frozenChicken/7.jpg";
import image8 from "../assets/Images/frozenChicken/8.jpg";
import image9 from "../assets/Images/frozenChicken/9.jpg";
import image10 from "../assets/Images/frozenChicken/10.jpg";
import image11 from "../assets/Images/frozenChicken/11.jpg";
import image12 from "../assets/Images/frozenChicken/12.jpg";
import image13 from "../assets/Images/frozenChicken/13.jpg";
import image14 from "../assets/Images/frozenChicken/14.jpg";
import image15 from "../assets/Images/frozenChicken/15.jpg";
import image16 from "../assets/Images/frozenChicken/16.jpg";
import image17 from "../assets/Images/frozenChicken/17.jpg";
import image18 from "../assets/Images/frozenChicken/18.jpg";
import image19 from "../assets/Images/frozenChicken/19.jpg";
import img1 from "../assets/Images/fastMeal/1.png";
import img2 from "../assets/Images/fastMeal/2.png";
import img3 from "../assets/Images/fastMeal/3.png";
import img4 from "../assets/Images/fastMeal/4.png";
import img5 from "../assets/Images/fastMeal/5.png";
import img6 from "../assets/Images/fastMeal/6.png";
import img7 from "../assets/Images/fastMeal/7.png";
import img8 from "../assets/Images/fastMeal/8.png";
import img9 from "../assets/Images/fastMeal/9.png";
import img10 from "../assets/Images/fastMeal/10.jpg";
import img11 from "../assets/Images/fastMeal/11.png";
import img12 from "../assets/Images/fastMeal/12.png";
import img13 from "../assets/Images/fastMeal/13.png";
import img14 from "../assets/Images/fastMeal/14.png";
import img15 from "../assets/Images/fastMeal/15.png";
import img16 from "../assets/Images/fastMeal/16.png";
import img17 from "../assets/Images/fastMeal/17.png";
import img18 from "../assets/Images/fastMeal/18.png";


export const frozedChicken = [
  {
    id: 1,
    name: "دجاج كامل مجمد بدون أحشاء",
    en_name: "Frozen whole chicken without giblets",
    img: image1,
  },
  {
    id: 2,
    name: "دجاج كامل مجمد بدون أحشاء",
    en_name: "Frozen whole chicken without giblets",
    img: image2,
  },
  {
    id: 3,
    name: "صدر دجاج (فيليه) مجمد،  بدون عظم و جلد",
    en_name: "Frozen chicken breast fillet, boneless and skinless",
    img: image3,
  },
  {
    id: 4,
    name: "فيليه دجاج داخلي مجمد",
    en_name: "Frozen indoor chicken fillet",
    img: image4,
  },
  {
    id: 5,
    name: "أفخاذ دجاج كاملة مجمدة",
    en_name: "Frozen whole chicken thighs",
    img: image5,
  },
  {
    id: 6,
    name: "سيقان دجاج مجمدة",
    en_name: "Frozen chicken legs",
    img: image6,
  },
  {
    id: 7,
    name: "أفخاذ دجاج (الجزء الأعلى) مجمدة",
    en_name: "Frozen chicken thighs (top part)",
    img: image7,
  },
  {
    id: 8,
    name: "أفخاذ دجاج مجمدة (الجزء  الأعلى بدون عظم و جلد)",
    en_name: "Frozen chicken thighs (boneless, skinless upper part)",
    img: image8,
  },
  {
    id: 9,
    name: "دجاج كامل منزوع العظم مجمد (شاورما)",
    en_name: "Frozen whole boneless chicken (shawarma)",
    img: image9,
  },
  {
    id: 10,
    name: "أجنحة دجاج مجمدة (ذو جزئين)",
    en_name: "Frozen chicken wings (two parts)",
    img: image10,
  },
  {
    id: 11,
    name: "أجنحة دجاج مجمدة (الجزء الوسط)",
    en_name: "Frozen chicken wings (middle part)",
    img: image11,
  },
  {
    id: 12,
    name: "أجنحة دجاج مجمدة (الجزء الأعلى)",
    en_name: "Frozen chicken wings (top part)",
    img: image12,
  },
  {
    id: 13,
    name: "ظهر دجاج سفلي مجمد",
    en_name: "Frozen chicken bottom back",
    img: image13,
  },
  {
    id: 14,
    name: "الجزء الأعلى من ظهر الدجاجة مجمد",
    en_name: "The upper back of the chicken is frozen",
    img: image14,
  },
  {
    id: 15,
    name: "رقبة دجاج مجمدة",
    en_name: "Frozen chicken neck",
    img: image15,
  },
  {
    id: 16,
    name: "أقدام دجاج مجمدة",
    en_name: "Frozen chicken feet",
    img: image16,
  },
  {
    id: 17,
    name: "حوصلة دجاج مجمدة",
    en_name: "Frozen chicken giblets",
    img: image17,
  },
  {
    id: 18,
    name: "لحم دجاج مجمد منزوع آلياً",
    en_name: "Frozen chicken meat, mechanically defrosted",
    img: image18,
  },
  {
    id: 19,
    name: "جلد دجاج مجمد",
    en_name: "Frozen chicken skin",
    img: image19,
  },
];
export const fastMeals = [
  {
    id: 1,
    name: "ناجتس الدجاج (مجمد)",
    en_name: "Chicken nuggets (frozen)",
    img: img1,
  },
  {
    id: 2,
    name: "فيليه الدجاج تيكا (مجمدة)",
    en_name: "Chicken fillet tikka (frozen)",
    img: img2,
  },
  {
    id: 3,
    name: "قطع فيليه الدجاج المقلية",
    en_name: "Fried chicken fillet pieces",
    img: img3,
  },
  {
    id: 4,
    name: "أجنحة الدجاج باربكيو المجمدة",
    en_name: "Frozen BBQ chicken wings",
    img: img4,
  },
  {
    id: 5,
    name: "أجنحة الدجاج بيري بيري المجمدة",
    en_name: "Frozen Peri Peri Chicken Wings",
    img: img5,
  },
  {
    id: 6,
    name: "أجنحة الدجاج المقرمشة (مجمدة)",
    en_name: "Crispy chicken wings (frozen)",
    img: img6,
  },
  {
    id: 7,
    name: "كرايات لحم الدجاج على الطريقة المكسيكية (مجمدة)",
    en_name: "Mexican style chicken meatballs (frozen)",
    img: img7,
  },
  {
    id: 8,
    name: "برجر الدجاج بالبقسماط (مجمد)",
    en_name: "Breaded chicken burger (frozen)",
    img: img8,
  },
  {
    id: 9,
    name: "برجر الدجاج الفاخر بالبقسماط (مجمد)",
    en_name: "Deluxe breaded chicken burger (frozen)",
    img: img9,
  },
  {
    id: 10,
    name: "فيليه الدجاج بالبقسماط المتبل (مجمد)",
    en_name: "Marinated breaded chicken fillet (frozen)",
    img: img10,
  },
  {
    id: 11,
    name: "فيليه دجاج بالبقسماط (مجمد)",
    en_name: "Breaded chicken fillet (frozen)",
    img: img11,
  },
  {
    id: 12,
    name: "شرائح الدجاج المتبلة المقلية على الطريقة الجنوبية (مجمدة)",
    en_name: "Southern style fried marinated chicken strips (frozen)",
    img: img12,
  },
  {
    id: 13,
    name: "شرائح الدجاج المتبلة سويت أند تشيلي (مجمدة)",
    en_name: "Sweet & Chili Marinated Chicken Strips (Frozen)",
    img: img13,
  },
  {
    id: 14,
    name: "شريحة دجاج  “شكيلنا”",
    en_name: "Chicken slice “Shakilna”",
    img: img14,
  },
  {
    id: 15,
    name: "أصابع الدجاج بالبقسماط",
    en_name: "Breaded chicken fingers",
    img: img15,
  },
  {
    id: 16,
    name: "دجاج شنيتزل هوم ستايل (مجمد)",
    en_name: "Homestyle Chicken Schnitzel (Frozen)",
    img: img16,
  },
  {
    id: 17,
    name: "ناجتس هوم ميد",
    en_name: "Homemade Nuggets",
    img: img17,
  },
  {
    id: 18,
    name: "بوب كورن الدجاج المتبل (مجمد)",
    en_name: "Marinated chicken popcorn (frozen)",
    img: img18,
  },
];


