// Home button

import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { PowerBtn } from '../components/AllSvgs'
import { BsPower } from 'react-icons/bs'

const Power = styled.button`
  position: fixed;
  top: 2rem;
  left: 50%;
  transform: translate(-50%, 0);

  background-color: #fcf6f4;
  border: none;
  padding: 0.3rem;
  //   border-radius: 50%;
  //   border: 1px solid #000;
  width: 2.5rem;
  height: 2.5rem;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;

  cursor: pointer;
  border-radius: 100%;

  //   &:hover {
  //     color: rgba(255, 0, 0, 0.6);
  //     box-shadow: 0 0 8px 6px rgba(255, 0, 0, 0.2);
  //   }

  & > *:first-child {
    text-decoration: none;
    color: inherit;
  }
`

const PowerButton = () => {
  return (
    <Power>
      <NavLink
        to='/'
        className='p-1.5 bg-[rgba(231,210,16,0.5)] hover:bg-[rgba(231,210,16,1)] transition rounded-full bg-red-200'
      >
        <BsPower size={30} color='green' />
      </NavLink>
    </Power>
  )
}

export default PowerButton
