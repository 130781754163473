import { frozedChicken } from '../../data/QualikoProducts'
import QualikoCard from './QualikoCard'

const FrozenChicken = ({ item }) => {
  console.log(item)
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full'>
      {frozedChicken.map((item) => (
        <QualikoCard item={item} />
      ))}
    </div>
  )
}

export default FrozenChicken
