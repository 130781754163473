import image1 from "../assets/Images/freshProducts/1.jpg";
import image2 from "../assets/Images/freshProducts/2.jpg";
import image3 from "../assets/Images/freshProducts/3.jpg";
import image4 from "../assets/Images/freshProducts/4.jpg";
import image5 from "../assets/Images/freshProducts/5.jpg";
import image6 from "../assets/Images/freshProducts/6.jpg";
import image7 from "../assets/Images/freshProducts/7.jpg";
import image8 from "../assets/Images/freshProducts/8.jpg";

export const freshProducts = [
  {
    id: 1,
    name: "المنتجات الغذائية الطازجة البطاطس المحلية",
    en_name: "FRESH FOOD LOCAL POTATOES PRODUCT",
    img: image1,
  },
  {
    id: 2,
    name: "دجاج فريش مجمد 1.9 كجم",
    en_name: "FREZON FRESH CHICKEN 1.9 KG",
    img: image2,
  },
  // {
  //   id: 3,
  //   name: "فريزون دجاج كامل 1.1 كجم",
  //   en_name: "FREZON WHOLE CHICKEN 1.1 KG",
  //   img: image3,
  // },
  {
    id: 4,
    name: "دجاج فريش مجمد 1.8 كجم",
    en_name: "FREZON FRESH CHICKEN 1.8 KG",
    img: image4,
  },
  // {
  //   id: 5,
  //   name: "فريزون صدر دجاج طري",
  //   en_name: "FREZON TENDER CHICKEN BREAST",
  //   img: image5,
  // },
  // {
  //   id: 6,
  //   name: "فريزون دجاج كامل 1.3 كجم",
  //   en_name: "FREZON WHOLE CHICKEN 1.3 KG",
  //   img: image6,
  // },
  {
    id: 7,
    name: "دجاج فريش مجمد 1.4 كجم",
    en_name: "FREZON FRESH CHICKEN 1.4 KG",
    img: image7,
  },
  // {
  //   id: 8,
  //   name: "فريزون دجاج فيليه داخلي",
  //   en_name: "FREZON CHICKEN INNER FILLET",
  //   img: image8,
  // },
];