import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Link } from '../components/AllSvgs'
import Chicken from '../assets/Images/qualikoIcon.png'

const Container = styled.div`
  position: relative;
  @media (max-width: 768px) {
    display: none;
  }
`
const Slider = styled.div`
  position: fixed;
  top: 0;
  right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: translateY(-100%);
  color: green;
  .chain {
    transform: rotate(135deg);
  }
`

const PreDisplay = styled.div`
  position: absolute;
  top: 0;
  right: 2rem;
`

const AnchorComponent = (props) => {
  const ref = useRef(null)
  const hiddenRef = useRef(null)

  useEffect(() => {
    const handleScroll = () => {
      let scrollPosition = window.pageYOffset
      let windowSize = window.innerHeight
      let bodyHeight = document.body.offsetHeight

      let diff = Math.max(bodyHeight - (scrollPosition + windowSize))
      //diff*100/scrollposition
      let diffP = (diff * 100) / (bodyHeight - windowSize)

      ref.current.style.transform = `translateY(${-diffP}%)`

      if (window.pageYOffset > 5) {
        hiddenRef.current.style.display = 'none'
      } else {
        hiddenRef.current.style.display = 'block'
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <Container>
      <PreDisplay ref={hiddenRef} className='hidden'>
        <img
          src={Chicken}
          alt='chicken'
          className='w-20 h-24 object-contain object-center'
        />
      </PreDisplay>
      <Slider ref={ref}>
        {[...Array(props.number)].map((x, id) => {
          return (
            <Link
              key={id}
              width={25}
              height={25}
              fill='currentColor'
              className='chain'
            />
          )
        })}
        <img
          src={Chicken}
          alt='chicken'
          className='w-20 h-24 object-contain object-center'
        />
      </Slider>
    </Container>
  )
}

export default AnchorComponent
