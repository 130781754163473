import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import img from '../assets/Images/bg.jpg'
import LogoComponent from '../subComponents/LogoComponent'
import SocialIcons from '../subComponents/SocialIcons'
import PowerButton from '../subComponents/PowerButton'
import AnchorComponent from '../subComponents/Anchor'
import BigTitle from '../subComponents/BigTitlte'
import { motion } from 'framer-motion'
import vid1 from '../assets/videos/vid1.MP4'
import vid2 from '../assets/videos/vid2.MP4'
import vid3 from '../assets/videos/vid3.MP4'
import vid4 from '../assets/videos/vid4.mp4'
import vid5 from '../assets/videos/vid5.mp4'

const MainContainer = styled(motion.div)`
  background-image: url(${img});
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
`
const Container = styled.div`
  background-color: ${(props) => `rgba(${props.theme.bodyRgba},0.8)`};
  width: 100%;
  height: auto;
  position: relative;
  padding-bottom: 5rem;
`

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10rem;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(calc(10rem + 15vw), 1fr));
  grid-gap: calc(1rem + 2vw);
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 3rem;
  }
`

// Framer-motion config
const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,

    transition: {
      staggerChildren: 0.5,
      duration: 0.5,
    },
  },
}

const BlogPage = () => {
  const [numbers, setNumbers] = useState(0)

  useEffect(() => {
    let num = (window.innerHeight - 70) / 30
    setNumbers(parseInt(num))
  }, [])

  const videos = [vid1, vid2, vid3, vid4, vid5]

  return (
    <MainContainer
      variants={container}
      initial='hidden'
      animate='show'
      exit={{
        opacity: 0,
        transition: { duration: 0.5 },
      }}
    >
      <Container>
        <LogoComponent />
        <PowerButton />
        <SocialIcons />
        <AnchorComponent number={numbers} />
        <Center>
          <Grid>
            {videos.map((vid) => {
              // return <BlogComponent key={blog.id} blog={blog}/>
              return (
                <video
                  key={vid}
                  src={vid}
                  controls
                  className='w-96 h-[216px]'
                ></video>
              )
            })}
          </Grid>
        </Center>
        <BigTitle text='BLOG' top='5rem' left='5rem' />
      </Container>
    </MainContainer>
  )
}

export default BlogPage
