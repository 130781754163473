import { useEffect, useRef } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { DarkTheme } from './Themes'
import { motion } from 'framer-motion'

import LogoComponent from '../subComponents/LogoComponent'
import SocialIcons from '../subComponents/SocialIcons'
import PowerButton from '../subComponents/PowerButton'

import Card from '../subComponents/Card'
import logo from '../assets/Images/logo.png'
import BigTitlte from '../subComponents/BigTitlte'
import LangIcon from '../subComponents/LangIcon'
import { useTranslation } from 'react-i18next'

const Box = styled.div`
  background-color: ${(props) => props.theme.body};
  position: relative;
  display: flex;
  align-items: center;
`
const Main = styled(motion.ul)`
  height: 40vh;
  display: flex;
  color: white;
`
const Rotate = styled.span`
  display: block;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  width: 80px;
  height: 80px;
  z-index: 1;
`

// Framer-motion Configuration
const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,

    transition: {
      staggerChildren: 0.5,
      duration: 0.5,
    },
  },
}

const TeamPage = () => {
  const { t, i18n } = useTranslation(['TeamData'])
  const teamData = [
    {
      id: 1,
      name: t('Khaled.Name'),
      description: t('Khaled.Desc'),
      tags: [t('Khaled.Tag')],
      email: 'mailto:khalidalnaser@freshfoodduhok.com',
      whatsapp: 'https://wa.me/+9647508372444',
    },
    {
      id: 2,
      name: t('Mahmoud.Name'),
      description: t('Mahmoud.Desc'),
      tags: [t('Mahmoud.Tag')],
      email: 'mailto:director_of_relations@freshfoodduhok.com',
      whatsapp: 'https://wa.me/+9647515296518',
    },
    {
      id: 3,
      name: t('salesIQ.Name'),
      description: '',
      tags: [t('salesIQ.Desc')],
      email: 'mailto:irqisales_manager@freshfoodduhok.com',
      whatsapp: 'https://wa.me/+9647500301000',
    },
    {
      id: 4,
      name: t('salesKRG.Name'),
      description: '',
      tags: [t('salesKRG.Desc')],
      email: 'mailto:kurdistansales_manager@freshfoodduhok.com',
      whatsapp: 'https://wa.me/+9647503233070',
    },
    {
      id: 5,
      name: t('accountant.Name'),
      description: '',
      tags: [t('accountant.Desc')],
      email: 'mailto:acc_manager@freshfoodduhok.com',
      whatsapp: 'https://wa.me/+9647514841264',
    },
  ]
  const team = i18n.language === 'ar' ? teamData.reverse() : teamData

  const ref = useRef(null)
  const yinyang = useRef(null)

  useEffect(() => {
    let element = ref.current

    const rotate = () => {
      element.style.transform = `translateX(${-window.pageYOffset}px)`

      return (yinyang.current.style.transform =
        'rotate(' + -window.pageYOffset + 'deg)')
    }

    window.addEventListener('scroll', rotate)
    return () => {
      window.removeEventListener('scroll', rotate)
    }
  }, [])

  const LogoImage = styled.img`
    transition: all 4s ease-in-out 1s;
  `

  return (
    <ThemeProvider theme={DarkTheme}>
      <LangIcon />
      <Box className='xl:h-[150vh] lg:h-[230vh] md:h-[250vh] h-[310vh] '>
        <LogoComponent theme='dark' />
        <SocialIcons theme='dark' />
        <PowerButton />
        <Main
          className='fixed top-[12rem] xl:left-[10rem] lg:left-[10rem] md:left-[5rem] left-[5rem] z-50'
          ref={ref}
          variants={container}
          initial='hidden'
          animate='show'
        >
          {team.map((d) => (
            <Card key={d.id} data={d} />
          ))}
        </Main>
        <Rotate ref={yinyang}>
          <LogoImage src={logo} alt='logo' />
        </Rotate>
        <BigTitlte text='TEAM' top='10%' right='20%' />
      </Box>
    </ThemeProvider>
  )
}

export default TeamPage
