// Here we will create dark and light theme variables

export const lightTheme = {
  body: '#FCF6F4',
  text: '#00ac63',
  fontFamily: "'Source Sans Pro', sans-serif",
  bodyRgba: '252, 246, 244',
  textRgba: '0,0,0',
}

export const DarkTheme = {
  body: '#00ac63',
  text: '#FCF6F4',
  fontFamily: "'Source Sans Pro', sans-serif",
  textRgba: '252, 246, 244',
  bodyRgba: '30,34,48',
}
