import cover from "../../assets/Images/qualikoCover.png";
import LangIcon from "../../subComponents/LangIcon";
import { useTranslation } from "react-i18next";
import QualikoTabs from "./QualikoTabs";
import PowerButton from "../../subComponents/PowerButton";
import LogoComponent from "../../subComponents/LogoComponent";

const Qualiko = () => {
  const { t, i18n } = useTranslation(["QualikoPage"]);
  return (
    <div dir={`${i18n.language == "ar" ? "rtl" : "rtl"}`}>
      <PowerButton />
      <LogoComponent />
      <LangIcon />
      <div className="relative h-80">
        <img
          className="w-full h-full object-cover object-center "
          src={cover}
          alt="cover"
        />
        <h1
          dir="ltr"
          className="text-xl md:text-4xl font-extrabold bg-white absolute z-50 bottom-9 inset-x-1/4 md:bottom-7 md:right-9 shadow-2xl rounded-md h-16 md:h-32 w-50 opacity-70 md:w-80 flex justify-center items-center text-center"
        >
          {t("title")}
        </h1>
      </div>
      <div className="relative -top-20">
        <QualikoTabs />
      </div>
    </div>
  );
};

export default Qualiko;
