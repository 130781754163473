export const en = {
  MainPage: {
    Click_here: "click here",
    Blog: "Blog",
    Products: "Products",
    About: "About",
    Team: "Team",
  },
  AboutPage: {
    FristParagraph:
      "In a world characterized by diversity and evolution, Fresh Food Duhok for General Trade stands out as a leader in the general trade in Iraq.",
    SecondParagraph:
      "We are not just a company, but pioneers in providing the best products and services. As a primary distributor of the Fresh brand, we embrace quality and excellence in every step we take.",
    ThirdParagraph:
      "We take pride in being the sole and exclusive agent for the Ukrainian company MHP's Qualiko brand in Iraq, reflecting our dedication to delivering the best products to our customers' doorsteps.",
    FourthParagraph:
      "We believe in integrity and orderliness in every aspect of our work, adhering to the highest global standards for health and safety. We're here to ensure that every product reaches you in ideal condition.",
    FifthParagraph:
      "Join us on the journey of quality and outstanding service. Fresh Food Duhok for General Trade—where unique taste meets high quality.",
  },
  Intro: {
    Title: "FRESH FOOD DUHOK COMPANY",
    SubTitle: "For General Trade, import and export",
  },
  Products: {
    qualikoButton: "Qualiko Products",
    freshButton: "Fresh Products",
    FristTitle: "Owner of the brand Fresh",
    FristDescription_A:
      "One of the first and leading trading companies in the region inproviding a distinguished quality of agricultural and food services, meat, chicken, processed meat and various parts of animals cuts.",
    FristDescription_B:
      "the company has designated pastures for raising sheep and other livestock. Our company also raises quail birds of the highest quality while maintaining honesty and integrity.",
    SecondTitle:
      "Exclusive and sole agent of the Ukrainian company MHP in Iraq for the Qulaiko brand",
    SecondDescription:
      "Exclusive dealer for the MHP company that produces chicken meat under the brand name Qulaiko as we are the owners of the brand Fresh Food Duhok for meat and chicken that is produced in our own farms.",
  },
  TeamData: {
    Khaled: {
      Name: "Khalid Omar Ahmed Al Nasir",
      Desc: "General Manager",
      Tag: "CEO & Founder",
    },
    Mahmoud: {
      Name: "Mahmood Ibrahim Atiya",
      Desc: "Relations Manager",
      Tag: "Director of relations",
    },
    salesIQ: {
      Name: "Sales Manager IQ",
      Desc: "Sales Manager",
    },
    salesKRG: {
      Name: "Sales Manager KRG",
      Desc: "Sales Manager",
    },
    accountant: {
      Name: "Accountant Manager",
      Desc: "Accountant",
    },
  },
  QualikoPage: {
    title: "Qualiko Products",
    tabs: {
      fristTab: "Frozen chicken",
      secondTab: "Fast-prepared meals",
    },
  },
};
