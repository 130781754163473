import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'

const LangIcon = () => {
  const { i18n } = useTranslation()
  return (
    <motion.button
      onClick={() => i18n.changeLanguage(i18n.language === 'ar' ? 'en' : 'ar')}
      className='fixed right-20 md:right-28 top-8 z-10 bg-green-600 text-white text-xl w-8 h-8 rounded-full'
      initial={{
        y: -200,
        transition: { type: 'spring', duration: 1.5, delay: 1 },
      }}
      animate={{
        y: 0,
        transition: { type: 'spring', duration: 1.5, delay: 1 },
      }}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
    >
      {i18n.language === 'ar' ? 'Ar' : 'En'}
    </motion.button>
  )
}

export default LangIcon
