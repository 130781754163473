import { motion } from "framer-motion";
import styled from "styled-components";
import { BsWhatsapp } from "react-icons/bs";

const Box = styled(motion.li)`
  width: 16rem;
  height: 40vh;
  background-color: ${(props) => props.theme.text};
  color: ${(props) => props.theme.body};
  padding: 1.5rem;
  margin-right: 4rem;
  border-radius: 0 50px 0 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${(props) => props.theme.body};
  transition: all 0.2s ease;

  &:hover {
    background-color: ${(props) => props.theme.body};
    color: ${(props) => props.theme.text};
    border: 1px solid ${(props) => props.theme.text};
  }
`;
const Title = styled.h2`
  font-size: calc(1.2em + 0.6vw);
  font-weight: 600;
`;
const Tags = styled.div`
  padding-top: 0.5rem;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`;
const Tag = styled.span`
  font-size: calc(0.8em + 0.2vw);
`;

const Link = styled.a`
  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};
  text-decoration: none;
  padding: 0.5rem calc(2rem + 1.5vw);
  border-radius: 0 0 0 50px;
  font-size: calc(1em + 0.5vw);

  ${Box}:hover & {
    background-color: ${(props) => props.theme.text};
    color: ${(props) => props.theme.body};
  }
`;

// Framer motion configuration
const Item = {
  hidden: {
    scale: 0,
  },
  show: {
    scale: 1,
    transition: {
      type: "spring",
      duration: 0.5,
    },
  },
};

const Card = (props) => {

  const { id, name, tags, email, whatsapp } = props.data;

  return (
    <Box key={id} variants={Item}>
      <Title>{name}</Title>
      <Tags>
        {tags.map((t, id) => (
          <Tag key={id}>{t}</Tag>
        ))}
      </Tags>
      <div className="flex justify-between items-center">
        <Link href={email} target="_blank" rel="noreferrer">
          Email
        </Link>
        <a href={whatsapp} target="_blank" rel="noreferrer">
          <BsWhatsapp size={30} />
        </a>
      </div>
    </Box>
  );
};

export default Card;
