import React from 'react'
import styled from 'styled-components'
import { DarkTheme } from '../components/Themes'

const Logo = styled.h1`
  display: inline-block;
  color: ${(props) =>
    props.color === 'dark' ? DarkTheme.text : DarkTheme.body};
  font-family: 'Pacifico', cursive;
  font-size: 2rem;

  position: fixed;
  z-index: 3;
`

const LogoComponent = (props) => {
  return (
    <Logo color={props.theme} className='left-4 lg:left-8 top-6 lg:top-8'>
      FFD
    </Logo>
  )
}

export default LogoComponent
