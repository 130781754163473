import { motion } from 'framer-motion'
import styled from 'styled-components'
import { DarkTheme } from '../components/Themes'
import { BsFacebook, BsInstagram, BsTiktok, BsYoutube } from 'react-icons/bs'

const Icons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: fixed;
  bottom: 0;

  z-index: 3;

  & > *:not(:last-child) {
    margin: 0.5rem 0;
  }
`

const Line = styled(motion.span)`
  width: 2px;
  height: 8rem;
  background-color: ${(props) =>
    props.color === 'dark' ? DarkTheme.text : DarkTheme.body};
`

const SocialIcons = (props) => {
  const socialLinks = [
    {
      id: 1,
      icon: BsYoutube,
      url: 'https://www.youtube.com/@F.F.DforGeneralTrade',
      title: 'youtube',
    },
    {
      id: 2,
      icon: BsInstagram,
      url: 'https://instagram.com/f.f.d_company?igshid=OGQ5ZDc2ODk2ZA==',
      title: 'instagram',
    },
    {
      id: 3,
      icon: BsFacebook,
      url: 'https://www.facebook.com/profile.php?id=61554560096811',
      title: 'facebook',
    },
    {
      id: 4,
      icon: BsTiktok,
      url: 'https://www.tiktok.com/@freshfoodduhok',
      title: 'tiktok',
    },
  ]
  return (
    <Icons className='left-4 lg:left-8'>
      {socialLinks.map((link) => (
        <motion.div
          key={link.id}
          initial={{ scale: 0 }}
          animate={{ scale: [0, 1, 1.5, 1] }}
          transition={{ type: 'spring', duration: 1, delay: 1 }}
        >
          <a
            style={{ color: 'inherit' }}
            target='_blank'
            rel='noreferrer'
            href={link.url}
          >
            <link.icon
              className='w-5 h-5 lg:w-6 lg:h-6'
              color={props.theme === 'dark' ? DarkTheme.text : DarkTheme.body}
            />
          </a>
        </motion.div>
      ))}

      <Line
        color={props.theme}
        initial={{
          height: 0,
        }}
        animate={{
          height: '8rem',
        }}
        transition={{
          type: 'spring',
          duration: 1,
          delay: 0.8,
        }}
      />
    </Icons>
  )
}

export default SocialIcons
