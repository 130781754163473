import React from 'react'
import styled, { keyframes } from 'styled-components'
import { motion } from 'framer-motion'
import logo from '../assets/Images/logo.png'
import { useTranslation } from 'react-i18next'

const Box = styled(motion.div)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  height: 55vh;
  display: flex;

  background: linear-gradient(
        to right,
        ${(props) => props.theme.body} 50%,
        ${(props) => props.theme.text} 50%
      )
      bottom,
    linear-gradient(
        to right,
        ${(props) => props.theme.body} 50%,
        ${(props) => props.theme.text} 50%
      )
      top;
  background-repeat: no-repeat;
  background-size: 100% 2px;
  border-left: 2px solid ${(props) => props.theme.body};
  border-right: 2px solid ${(props) => props.theme.text};

  z-index: 1;
`
const SubBox = styled.div`
  width: 50%;
  position: relative;
  display: flex;

  .pic {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
  }
`
const Text = styled.div`
  font-size: calc(1.1em + 1.6vw);
  font-weight: 600;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  & > *:last-child {
    font-size: calc(0.5rem + 1.5vw);
    font-weight: 300;
  }
`
const float = keyframes`
  0% {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  }
  50% {
  -webkit-transform: scale3d(1.05, 1.05, 1.05);
  transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  }
`
const LogoImage = styled.img`
  animation: ${float} 1s ease infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
`

const Intro = () => {
  const { i18n, t } = useTranslation(['Intro'])
  return (
    <Box
      initial={{ height: 0 }}
      animate={{ height: '55vh' }}
      transition={{ type: 'spring', duration: 2, delay: 1 }}
      className='w-[70vw] md:w-[65vw]'
    >
      <SubBox>
        <Text
          className={`p-2 lg:p-8 ${
            i18n.language === 'ar' ? '!text-[#1e2230]' : 'text-white'
          }`}
        >
          <h1>{t('Title')}</h1>
          <h6>{t('SubTitle')}</h6>
        </Text>
      </SubBox>
      <SubBox>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 2 }}
          className='flex items-center justify-center'
        >
          {/* <img className='pic w-full lg:w-[60%]' src={Me} alt='Profile Pic' /> */}
          <LogoImage
            src={logo}
            className='w-44 md:w-96 lg:w-[440px]'
            alt='logo'
          />
        </motion.div>
      </SubBox>
    </Box>
  )
}

export default Intro
