import FrozenChicken from "../../subComponents/Qualiko/FrozenChicken";
import FastMeals from "../../subComponents/Qualiko/FastMeals";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const QualikoTabs = () => {
  const { i18n, t } = useTranslation(["QualikoPage"]);
  const [value, setValue] = useState(0);
  const tabs = [
    {
      id: 1,
      tab: t("tabs.fristTab"),
    },
    {
      id: 2,
      tab: t("tabs.secondTab"),
    },
  ];

  return (
    <div className="max-w-7xl flex justify-center items-center mx-auto">
      <div className="w-full px-2 py-16 sm:px-0">
        <div className="flex items-center gap-6 justify-center">
          {tabs.map((tab, i) => (
            <button
              onClick={() => setValue(i)}
              className={`w-60 h-20 text-white rounded-md ${
                value === i ? "bg-green-600" : "bg-slate-900"
              }`}
              key={tab.id}
            >
              {tab.tab}
            </button>
          ))}
        </div>
        <div className="">
          {value === 0 ? <FrozenChicken /> : <FastMeals />}
        </div>
      </div>
    </div>
  );
};
export default QualikoTabs;
