import { useTranslation } from 'react-i18next'

const QualikoCard = ({ item }) => {
  const { i18n } = useTranslation()
  return (
    <div
      key={item.id}
      class='mx-auto mt-11 w-80 transform overflow-hidden rounded-lg bg-white shadow-md duration-300 hover:scale-105 hover:shadow-lg'
    >
      <img
        class='h-48 w-full object-cover object-center'
        src={item?.img}
        alt='Product Image'
      />
      <div class='p-4'>
        <h2 class='mb-2 text-lg font-medium text-gray-900 flex justify-center items-center text-center'>
          {i18n.language === 'ar' ? item.name : item.en_name}
        </h2>
      </div>
    </div>
  )
}

export default QualikoCard
