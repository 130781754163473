import styled, { ThemeProvider } from "styled-components";
import { lightTheme } from "./Themes";
import LogoComponent from "../subComponents/LogoComponent";
import SocialIcons from "../subComponents/SocialIcons";
import PowerButton from "../subComponents/PowerButton";
import ParticleComponent from "../subComponents/ParticleComponent";
import BigTitle from "../subComponents/BigTitlte";
import LangIcon from "../subComponents/LangIcon";
import { useTranslation } from "react-i18next";
import qualiko from "../assets/Images/qualikoIcon.png";
import fresh from "../assets/Images/freshIcon.png";
import { Link } from "react-router-dom";

const Box = styled.div`
  background-color: ${(props) => props.theme.body};
  width: 100vw;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const Main = styled.div`
  border: 2px solid ${(props) => props.theme.text};
  color: ${(props) => props.theme.text};
  background-color: ${(props) => props.theme.body};
  padding: 2rem;
  // width: 30vw;
  // height: 60vh;
  z-index: 3;
  line-height: 1.5;
  cursor: pointer;

  font-family: "Ubuntu Mono", monospace;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    color: ${(props) => props.theme.body};
    background-color: ${(props) => props.theme.text};
  }
`;

const Title = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(1em + 1vw);

  ${Main}:hover & {
    & > * {
      fill: ${(props) => props.theme.body};
    }
  }

  & > *:first-child {
    margin-right: 1rem;
  }
`;

const Description = styled.div`
  color: ${(props) => props.theme.text};
  font-size: calc(0.5em + 0.8vw);
  padding: 0.5rem 0;

  ${Main}:hover & {
    color: ${(props) => props.theme.body};
  }

  strong {
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
  ul,
  p {
    margin-left: 2rem;
  }
`;

const Products = () => {
  const { t } = useTranslation(["Products"]);
  return (
    <ThemeProvider theme={lightTheme}>
      <Box className="min-h-screen py-32 lg:py-0 lg:h-screen">
        <LangIcon />
        <LogoComponent theme="light" />
        <SocialIcons theme="light" />
        <PowerButton />
        <ParticleComponent theme="light" />
        <div className="flex flex-col lg:flex-row items-center gap-10 max-w-6xl mx-auto px-12 z-50">
          <div className="flex flex-col justify-center items-center gap-5">
            <Main>
              <Title>{t("FristTitle")}</Title>
              <Description>{t("FristDescription_A")}</Description>
              <Description>{t("FristDescription_B")}</Description>
            </Main>
            <Link
              to="/freshProducts"
              className="relative group flex items-center justify-center z-50"
            >
              <img
                src={fresh}
                alt="icon"
                href="/freshProducts"
                className="rounded-md cursor-pointer object-center object-cover h-24 z-50"
              />
              <p className="ease-in-out text-yellow-600 transition-all delay-150 group-hover:-translate-x-20 duration-300 cursor-pointer absolute top-1/2 translate-y-[-50%] opacity-0 group-hover:opacity-100">
                {t("freshButton")}
              </p>
            </Link>
          </div>
          <div className="flex flex-col justify-center items-center gap-5">
            <Main>
              <Title>{t("SecondTitle")}</Title>
              <Description>{t("SecondDescription")}</Description>
            </Main>
            <Link
              to="/qualiko"
              className="relative group flex items-center justify-center z-50"
            >
              <img
                src={qualiko}
                alt="fresh"
                href="/qualiko"
                className="rounded-md cursor-pointer object-center object-cover h-24 z-50"
              />
              <p className="ease-in-out text-green-600 whitespace-nowrap transition-all delay-150 group-hover:-translate-x-20 duration-300 cursor-pointer absolute top-1/2 translate-y-[-50%] opacity-0 group-hover:opacity-100">
                {t("qualikoButton")}
              </p>
            </Link>
          </div>
        </div>

        <BigTitle text="PRODUCTS" top="75%" right="30%" />
      </Box>
    </ThemeProvider>
  );
};

export default Products;
