import { freshProducts } from "../data/FreshProducts";
import FreshproductsCard from "./FreshproductsCard";
import LangIcon from "../subComponents/LangIcon";
import cover from "../assets/Images/qualikoCover.png";
import { useTranslation } from "react-i18next";
import LogoComponent from "../subComponents/LogoComponent";
import PowerButton from "../subComponents/PowerButton";

const FreshProducts = () => {
  const { i18n } = useTranslation();
  return (
    <div dir={`${i18n.language == "ar" ? "rtl" : "rtl"}`}>
      <PowerButton />
      <LogoComponent />
      <LangIcon />
      <div className="relative h-80">
        <img
          className="w-full h-full object-cover object-center "
          src={cover}
          alt="cover"
        />
        <h1
          dir="ltr"
          className="text-xl md:text-4xl font-extrabold bg-white absolute z-50 bottom-9 inset-x-1/4 md:bottom-7 md:right-9 shadow-2xl rounded-md h-16 md:h-32 w-50 opacity-70 md:w-80 flex justify-center items-center text-center"
        >
          {`${i18n.language == "ar" ? "منتجات فريش" : "Fresh Products"}`}
        </h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 py-8 w-full">
        {freshProducts.map((item) => (
          <FreshproductsCard item={item} />
        ))}
      </div>
    </div>
  );
};

export default FreshProducts;
