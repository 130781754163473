import { Routes, Route, useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { lightTheme } from "./components/Themes";
import { AnimatePresence } from "framer-motion";
import GlobalStyle from "./globalStyles";

//Components
import Main from "./components/Main";
import AboutPage from "./components/AboutPage";
import BlogPage from "./components/BlogPage";
import TeamPage from "./components/TeamPage";
import Products from "./components/Products";
import SoundBar from "./subComponents/SoundBar";
import { useTranslation } from "react-i18next";
import Qualiko from "./components/Qualiko/Qualiko";
import FreshProducts from "./components/FreshProducts";

function App() {
  const { i18n } = useTranslation();
  const location = useLocation();
  return (
    <div dir={`${i18n.language == "ar" ? "rtl" : "ltr"}`}>
      <GlobalStyle />
      <ThemeProvider theme={lightTheme}>
        <SoundBar />
        {/* For framer-motion animation on page change! */}
        {/* Changed prop from exitBefore to mode */}
        <AnimatePresence mode="wait">
          {/* Changed Switch to Routes */}

          <Routes key={location.pathname} location={location}>
            {/* Changed component to element */}

            <Route path="/" element={<Main />} />

            <Route path="/about" element={<AboutPage />} />

            <Route path="/blog" element={<BlogPage />} />

            <Route path="/team" element={<TeamPage />} />

            <Route path="/products" element={<Products />} />

            <Route path="/qualiko" element={<Qualiko />} />
            
            <Route path="/freshProducts" element={<FreshProducts />} />
            {/* Below is to catch all the other routes and send the user to main component,
you can add custom 404 component or message instead of Main component*/}
            <Route path="*" element={<Main />} />
          </Routes>
        </AnimatePresence>
      </ThemeProvider>
    </div>
  );
}

export default App;
