import styled, { keyframes, ThemeProvider } from "styled-components";
import { DarkTheme } from "./Themes";

import LogoComponent from "../subComponents/LogoComponent";
import SocialIcons from "../subComponents/SocialIcons";
import PowerButton from "../subComponents/PowerButton";
import ParticleComponent from "../subComponents/ParticleComponent";
import BigTitle from "../subComponents/BigTitlte";
import astronaut from "../assets/Images/logo.png";
import { useTranslation } from "react-i18next";
import LangIcon from "../subComponents/LangIcon";

const Box = styled.div`
  background-color: ${(props) => props.theme.body};
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
`;
const float = keyframes`
0% { transform: translateY(-10px) }
50% { transform: translateY(15px) translateX(15px) }
100% { transform: translateY(-10px) }

`;
const Spaceman = styled.div`
  position: absolute;
  top: 25%;
  right: 5%;
  height: 30vw;
  width: auto;
  animation: ${float} 4s ease infinite;
  img {
    width: 100%;
    height: auto;
  }
`;
const Main = styled.div`
  border: 2px solid ${(props) => props.theme.text};
  color: ${(props) => props.theme.text};
  z-index: 3;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  // font-size: calc(0.6rem + 0.8vw);
  backdrop-filter: blur(4px);

  position: absolute;
  font-family: "Ubuntu Mono", monospace;
  font-style: italic;
`;

const AboutPage = () => {
  const { i18n, t } = useTranslation(["AboutPage"]);
  return (
    <ThemeProvider theme={DarkTheme}>
      <Box>
        <LangIcon className="" />
        <LogoComponent theme="dark" />
        <SocialIcons theme="dark" />
        <PowerButton />
        <ParticleComponent theme="dark" />

        <Spaceman>
          <img src={astronaut} alt="spaceman" />
        </Spaceman>
        <Main className="p-4 lg:p-8 w-3/4 h-4/5 text-xs sm:text-sm lg:text-base top-24 lg:top-28 left-1/2 -translate-x-1/2 lg:left-[40%] lg:w-1/2">
          {/* في عالم يتسم بالتنوع والتطور، تبرز شركة طعام طازج كرائدة في مجال التجارة العامة في العراق. نحن لسنا مجرد شركة، بل نحن رواد في توفير أفضل المنتجات والخدمات. باعتبارنا موزع رئيسي للعلامة التجارية فريش، نتبنى الجودة والتميز في كل خطوة نقوم بها. نفخر بكوننا الوكيل الوحيد والحصري لشركة MHP الأوكرانية للعلامة التجارية كواليكو في العراق، وهذا يعكس تفانينا في تقديم أفضل المنتجات إلى عتبات منازل عملائنا. نحن نؤمن بالنزاهة والنظام في كل جانب من جوانب عملنا، ونلتزم بأعلى المعايير العالمية للصحة والسلامة. نحن هنا لنضمن أن كل منتج يصل إليك بحالة مثالية. انضم إلينا في رحلة الجودة والخدمة المتميزة. طعام طازج للتجارة العامة - حيث الطعم الفريد يلتقي بالجودة العالية. */}
          {t('FristParagraph')}
          <br />
          <br />
          {t('SecondParagraph')}
          <br />
          <br />
          {t('ThirdParagraph')}
          <br />
          <br />
          {t('FourthParagraph')}
          <br />
          <br />
          {t('FifthParagraph')}
        </Main>

        <BigTitle text="ABOUT" top="10%" left="5%" />
      </Box>
    </ThemeProvider>
  );
};

export default AboutPage;
