import { fastMeals } from '../../data/QualikoProducts'
import QualikoCard from './QualikoCard'

const FastMeals = ({ item }) => {
  console.log(item)
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full'>
      {fastMeals.map((item) => (
        <QualikoCard item={item} />
      ))}
    </div>
  )
}

export default FastMeals
